@import '../../../styles/vars';

.btn {
    border: none;
    display: inline-flex !important;
    align-items: center;
    gap: 8px;
    border-radius: 0.25rem;
    height: 50px;
    padding: 16px 28px;
    justify-content: center !important;
    align-items: center;
    font-size: 15px;
    font-weight: 600;
    line-height: 120%;
    letter-spacing: -0.03em;
    text-transform: capitalize;
    text-decoration: none !important;
    cursor: pointer;
    width: fit-content;
    transition: $anim;
    position: relative;

    &.formBtn {
        &--isLoading {
            height: 50px;
            position: relative;
            pointer-events: none;

            svg {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
}

.blue {
    color: white;
    background: #4769d8;
    transition: background 0.4s ease-in-out;
    &:hover {
        background: #597be8;
    }
}

.black {
    color: $white;
    background: $dark;

    &:hover {
        background: #232d50;
    }
}

.standard {
    display: flex;
    align-items: center;
    color: $dark;
    background: #ebecef;

    svg {
        transition: $anim;
    }

    &:hover {
        background: #e4e5ea;

        svg {
            transform: rotate(45deg);
        }
    }
}

.blue__divided {
    color: white;
    background: #4769d8;
    gap: 1rem;
    transition: background 0.4s ease-in-out;
    span:first-child {
        position: relative;

        &::after {
            display: block;
            content: '';
            height: 14px;
            width: 2px;
            background: rgba(255, 255, 255, 0.3);
            position: absolute;
            top: 50%;
            right: -8px;
            transform: translate(50%, -50%);
            border-radius: 100px;
        }
    }

    &:hover {
        background: #597be8;
    }
}

@media (max-width: 1200px) {
    .btn {
        padding: 1rem 1.5rem;
        height: 49px;

        &.formBtn {
            &--isLoading {
                height: 53px;
            }
        }
    }
}

@media (max-width: 480px) {
    .btn {
        padding: 1rem 1.25rem;

        &.formBtn {
            width: 100%;
            padding: 17px 28px;
            height: 53px;
        }
    }
}

@media (max-width: 376px) {
    .btn {
        padding: 0.75rem 1rem;
        height: 43px;
    }
}
