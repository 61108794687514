.logo {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    position: relative !important;
    width: 176px;
    img {
        aspect-ratio: 176 / 24;
        position: relative !important;
        width: auto;
        height: auto;
    }
}

@media (max-width: 1024px) {
    .logo {
        width: 159px;
    }
}

@media (max-width: 820px) {
    .logo {
        width: 176px;
    }
}
