@import '../../../styles/vars';

.bundle__section {
    background: #0d0e14;
    display: flex;
    overflow: hidden;
    position: relative !important;
}

.section__info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 890px;
    margin: 0 auto;
    text-align: center;
    padding-bottom: 4rem;
    height: 100%;
    flex-grow: 1;
}

.section__logo {
    width: 52px;

    img {
        aspect-ratio: 1 / 1;
        position: relative !important;
    }
}

.heading {
    padding-bottom: 1rem;
    color: $white;
    line-height: 73%;
    .word__wrapper {
        line-height: 100%;

        span {
            transform: translateY(100%);
            line-height: 114%;
        }
    }
    span {
        display: inline-block;
        font-weight: 600;
    }
}

.word__wrapper {
    overflow-y: hidden;
    overflow-x: auto;
}

.animate__heading {
    .word__wrapper span {
        animation: $headingFade;
    }
}

.animate__excerpt {
    line-height: 100%;

    .word__wrapper span {
        animation: $excerptFade;
    }
}

.excerpt {
    color: #bbbbbc;
    max-width: 650px;
    margin: auto;
    line-height: 73%;

    .word__wrapper {
        line-height: 100%;

        span {
            transform: translateY(100%);
            line-height: 140%;
        }
    }

    span {
        display: inline-block;
    }
}

.buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.75rem;
}

.button {
    svg {
        transition: transform 0.4s ease-in-out;
        display: block;
    }

    &:hover {
        svg {
            transform: rotate(45deg);
        }
    }
}

.thesis__list {
    position: absolute;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
    max-width: 500px;
    bottom: 4rem;
}

.thesis__item {
    display: flex;
    align-items: center;
    gap: 0.375rem;
    color: $white;

    span {
        font-size: 15px;
        font-weight: 600;
        line-height: 120%;
        letter-spacing: -0.03em;
        text-transform: capitalize;
    }
}

.animate__point {
    animation: tinyFadeIn 0.4s ease-in-out forwards;
}

.swiper {
    position: relative !important;
    top: 0;
    flex-shrink: 0;
    margin: 0;
}

.swiper__left {
    left: 0;
    transform: translateX(-18%);
    opacity: 0;
    animation: fadeIn 0.6s ease-in-out 1s forwards;
}

.swiper__right {
    right: 0;
    transform: translateX(18%);
    opacity: 0;
    animation: fadeIn 0.6s ease-in-out 1s forwards;
}

.column__block {
    width: 26.46vw;
    aspect-ratio: 289 / 145;
    height: auto;
    position: relative !important;

    img {
        aspect-ratio: 289 / 145;
        position: relative !important;
        border-radius: 4px;
    }
}

@media (min-width: 1921px) {
    .swiper__left {
        left: 0;
        transform: translateX(-3%);
    }

    .swiper__right {
        right: 0;
        transform: translateX(3%);
    }
}

@media (max-width: 1700px) {
    .section__info {
        max-width: 50rem;
    }

    .swiper__left {
        transform: translateX(-25%);
    }

    .swiper__right {
        transform: translateX(25%);
    }
}

@media (max-width: 1480px) {
    .section__info {
        max-width: 42rem;
        padding-bottom: 3rem;
    }

    .thesis__list {
        bottom: 3rem;
        gap: 0.75rem;
    }
}

@media (max-width: 1200px) {
    .section__info {
        padding-bottom: 2.5rem;
        max-width: 38rem;
    }

    .thesis__list {
        gap: 0.5rem;
        bottom: 2.5rem;
    }
}

@media (max-width: 1024px) {
    .bundle__section {
        width: auto;
    }

    .section__info {
        padding-bottom: 2rem;
        text-align: center;
        align-items: center;
        max-width: 34rem !important;
    }

    .info__top {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .excerpt {
        padding-bottom: 1.25rem;
        max-width: 535px;
    }

    .column__block {
        width: 23vw;
    }

    .swiper__left {
        transform: translateX(-35%);
    }

    .swiper__right {
        transform: translateX(35%);
    }

    .thesis__list {
        bottom: 2rem;
    }
}

@media (max-width: 820px) {
    .section__info {
        max-width: 33rem;
    }
}

@media (max-width: 768px) {
    .bundle__section {
        flex-direction: column;
        gap: 1rem;
        padding-top: 4rem;
        // margin-bottom: 4rem;
    }

    .section__info {
        max-width: 28rem;
        justify-content: flex-start;
        order: -1;
        padding: 0 2rem 2rem;
    }

    .buttons {
        padding-bottom: 1.5rem;
    }

    .thesis__list {
        position: static;
    }

    .swiper {
        height: auto;

        .swiper-wrapper {
            height: auto;
        }
    }

    .column__block {
        width: 500px;
        position: static;
        height: auto !important;
        flex-shrink: 0;
    }

    .swiper__left {
        order: 0;
        transform: translate(0);
    }

    .swiper__right {
        transform: translate(0);
        order: 1;
        display: none;
    }
}

@media (max-width: 576px) {
    // .bundle__section {
    //     margin-bottom: 3rem;
    // }
    .column__block {
        width: 400px;
    }

    .section__info {
        padding: 0 1.5rem 1.5rem;
    }
}

@media (max-width: 480px) {
    .buttons {
        flex-direction: column;

        button,
        a {
            width: 100%;
        }
    }

    .bundle__section {
        gap: 0.75rem;
    }

    .thesis__item {
        span {
            font-size: 0.9375rem;
        }
    }

    .column__block {
        width: 360px;
    }
}

@media (max-width: 414px) {
    .heading {
        padding-bottom: 1rem;
    }

    .excerpt {
        padding-bottom: 1rem;
    }

    .section__info {
        padding: 0 1.25rem 1.5rem;
    }

    .column__block {
        width: 320px;
    }
}

@media (max-width: 376px) {
    .thesis__item {
        span {
            font-size: 0.875rem;
        }
    }

    .column__block {
        width: 280px;
    }

    .bundle__section {
        padding-top: 3rem;
    }
}
