@import '../../styles/vars';
@import '../../styles/keyframes.scss';

.adv__list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 3rem;
    grid-row-gap: 3rem;
}

.adv__item {
    flex: 1;
}

.logo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    border-radius: 6px;
    background: #f5f6f7;
    margin-bottom: 2rem;
}

.logo__wrapper {
    width: 1.75rem;
    position: relative !important;
    img {
        aspect-ratio: 1 / 1;
        position: relative !important;
    }
}

.name {
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 120%;
    letter-spacing: -0.05em;
    text-transform: capitalize;
    padding-bottom: 0.75rem;
}

.excerpt {
    color: $excerpt;
    font-size: 16px;
    max-width: 380px;
    padding-bottom: 0;
    letter-spacing: -0.02em;
}

@media (max-width: 1480px) {
    .adv__list {
        grid-column-gap: 2rem;
    }
}

@media (max-width: 1200px) {
    .adv__list {
        grid-template-columns: repeat(2, 1fr);
        grid-row-gap: 2rem;
    }

    .excerpt {
        font-size: 1rem;
    }
}

@media (max-width: 576px) {
    .adv__list {
        grid-row-gap: 1.5rem;
        grid-column-gap: 1.5rem;
    }

    .logo {
        margin-bottom: 1.5rem;
    }
}

@media (max-width: 480px) {
    .adv__list {
        grid-template-columns: repeat(1, 1fr);
    }

    .logo {
        width: 3.5rem;
        height: 3.5rem;
    }
    .logo__wrapper {
        width: 1.5rem;
    }

    .excerpt {
        font-size: 0.9375rem;
    }
}

@media (max-width: 376px) {
    .excerpt {
        font-size: 0.875rem;
    }

    .name {
        font-size: 1.25rem;
    }
}
