.sticky {
    position: sticky;
    top: 0;
    z-index: 12;
    transition: top 0.4s ease;
}

.sticky__hide {
    top: -100px;
    transition: top 0.4s ease;
}
