@import '../../../styles/vars';

.header {
    position: relative;
    background-color: $white;
    flex-shrink: 0;
    height: 94px;
    z-index: 8;
    border-bottom: 1px solid #e4e5ea;
    z-index: 10;
    transition: background-color 0.4s ease;

    svg path {
        stroke: $dark;
    }

    &::after {
        content: '';
        display: block;
        position: fixed;
        width: 100%;
        height: calc(100vh - 70px);
        top: 70px;
        left: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.5);
        z-index: 1;
        opacity: 0;
        visibility: hidden;
        transition:
            visibility 0.4s linear,
            opacity 0.4s cubic-bezier(0.4, 0, 0.2, 1);
    }

    &.navOpen {
        @media screen and (max-width: 820px) {
            &::after {
                visibility: visible;
                opacity: 1;
            }
        }
    }
}

.colored {
    border-bottom: 1px solid #1b1b1b;
    background-color: #0d0e14;

    .burger__line {
        background: $white;
    }

    svg path {
        stroke: $white;
    }
}

.wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
}

.burger {
    display: none;
    width: 2.25rem;
    height: 2.25rem;
    flex-direction: column;
    justify-content: center;
    gap: 5px;
    padding: 8px;
    position: relative;

    &--open {
        .burger__line {
            &:nth-child(1) {
                top: 18px;
                width: 0%;
                left: 50%;
                transform: translateX(-50%);
                opacity: 0;
            }

            &:nth-child(2) {
                -webkit-transform: rotate(45deg);
                -moz-transform: rotate(45deg);
                -o-transform: rotate(45deg);
                transform: rotate(45deg);
            }

            &:nth-child(3) {
                -webkit-transform: rotate(-45deg);
                -moz-transform: rotate(-45deg);
                -o-transform: rotate(-45deg);
                transform: rotate(-45deg);
            }

            &:nth-child(4) {
                top: 18px;
                width: 0%;
                left: 50%;
                transform: translateX(-50%);
                opacity: 0;
            }
        }
    }

    &__line {
        height: 0.125rem;
        background: $dark;
        width: 20px;
        position: absolute;
        left: 8px;
        opacity: 1;
        transition:
            top 0.2s ease-in-out,
            width 0.2s ease-in-out,
            left 0.2s ease-in-out,
            transform 0.2s ease-in-out,
            opacity 0.2s ease-in-out;

        &:nth-child(1) {
            top: 11px;
        }

        &:nth-child(2),
        &:nth-child(3) {
            top: 18px;
        }

        &:nth-child(4) {
            top: 25px;
        }
    }
}

@media (max-width: 820px) {
    .header {
        height: 70px;
    }

    .header__color {
        background: $white;
    }

    .burger {
        display: flex;
    }
}
