@import '../../styles/vars';

.nav__list {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.bundle {
    font-weight: 600;
}

.nav__item {
    color: $dark;
    background-color: transparent;

    a {
        display: flex;
        align-items: center;
        gap: 0.25rem;
        font-size: 16px;
        padding: 0.375rem 1rem;
        border-radius: 4px;
        letter-spacing: -0.01em;
        color: $dark;
        transition: background-color 0.4s ease;

        &:hover {
            background-color: #f5f6f7;
        }
    }
}

.btnWrapper {
    display: none;
}

.dark__mode {
    .nav__item {
        color: $white;

        a {
            color: $white;

            &:hover {
                background-color: rgba(255, 255, 255, 0.07);
            }
        }
    }

    .currPage {
        a {
            background-color: #343435;
        }
    }
}

.currPage {
    a {
        background-color: #f5f6f7;
    }
}

@media (max-width: 1200px) {
    .nav__item {
        a {
            font-size: 16px;
        }
    }
}

@media (max-width: 820px) {
    .nav {
        display: flex;
        flex-direction: column;
        gap: 1.25rem;
        position: fixed;
        width: 100%;
        height: calc(100vh - 70px);
        left: 0;
        right: 0;
        padding: 28px 24px 40px 24px;
        background-color: $white;
        top: 70px;
        transform: translateX(110%);
        transition: transform 0.4s ease-in-out;
        z-index: 2;

        &.active {
            transform: translateX(0);
        }
    }

    .btnWrapper {
        display: flex;
    }

    .dark__mode {
        background: #0d0e14;

        .nav__item {
            a {
                &::after {
                    background-color: $white;
                }
            }
        }

        .currPage {
            a {
                padding: 12px 0;
                background-color: transparent;
                background-size: 100% 2px;
                background-position: right calc(100% - 10px);
                background-image: linear-gradient(to right, #fff 0%, #fff 100%);

                &:hover {
                    background-color: transparent;
                }
            }
        }
    }

    .nav__list {
        flex-direction: column;
        align-items: flex-start;
        gap: 0;
        overflow-y: auto;
    }

    .nav__item {
        a {
            padding: 12px 0;
            position: relative;
            display: inline-block;
            transition: background-size 0.4s ease-in-out;
            background-position: left calc(100% - 10px);
            background-size: 0% 2px;
            background-image: linear-gradient(to right, #0a163c 0%, #0a163c 100%);
            background-repeat: no-repeat;
            text-decoration: none;

            &:hover {
                background-color: transparent;
            }
        }

        span:first-child {
            order: 1;
        }
    }

    .currPage {
        a {
            padding: 12px 0;
            background-color: transparent;
            background-size: 100% 2px;
            background-position: right calc(100% - 10px);

            &:hover {
                background-color: transparent;
            }
        }
    }
}

@media (max-width: 480px) {
    .btnWrapper {
        .nav__btn {
            width: 100%;
        }
    }
}

@media (max-width: 414px) {
    .nav {
        padding: 28px 20px 40px 20px;
    }
}
