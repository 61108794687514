@import '../../../styles/vars';

.footer {
    background: $white;
    padding: 3rem 0;
    flex-shrink: 0;
    background: $white;
    border-top: 1px solid #e4e5ea;
}

.wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.copyright {
    color: #848b9d;
    text-align: right;
    font-size: 14px;
    letter-spacing: -0.02em;
}

.nav__list {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.bundle {
    font-weight: 600;
}

.nav__item {
    color: $dark;

    a {
        display: flex;
        align-items: center;
        position: relative;
        gap: 0.25rem;
        font-size: 16px;
        letter-spacing: -0.01em;
        color: $dark;
        padding: 6px 16px;
        border-radius: 4px;
        transition: background-color 0.4s ease;

        &:hover {
            background-color: #f4f4f4;
        }
    }
}

.currPage {
    a {
        background-color: #f4f4f4;
    }
}

@media (max-width: 1200px) {
    .nav__item {
        a {
            font-size: 16px;
        }
    }
}

@media (max-width: 1024px) {
    .wrapper {
        flex-wrap: wrap;
    }

    .nav__list {
        gap: 1rem;
    }

    .copyright {
        width: 100%;
        text-align: left;
        padding-top: 2rem;
    }
}

@media (max-width: 768px) {
    .nav {
        width: 100%;
        padding-top: 1.5rem;
    }

    .nav__item {
        &:first-child {
            a {
                padding: 6px 16px 6px 0;
            }
        }

        a {
            &:hover {
                background-color: transparent;
            }
        }
    }

    .currPage {
        &:first-child {
            a {
                padding: 6px 16px;
            }
        }

        a {
            &:hover {
                background-color: #f4f4f4;
            }
        }
    }
}

@media (max-width: 640px) {
    .nav__list {
        width: 100%;
        justify-content: space-between;
        gap: 0;
    }

    .copyright {
        padding-top: 1.5rem;
    }
}

@media (max-width: 576px) {
    .nav {
        padding-top: 1.5rem;
    }

    .nav__item {
        a {
            padding: 12px 0;
            position: relative;
            display: inline-block;
            transition: background-size 0.4s ease-in-out;
            background-position: left calc(100% - 10px);
            background-size: 0% 2px;
            background-image: linear-gradient(to right, #0a163c 0%, #0a163c 100%);
            background-repeat: no-repeat;
            text-decoration: none;

            &:hover {
                background-color: transparent;
            }
        }

        &:first-child {
            a {
                padding: 6px 0 12px 0;
            }
        }

        span:first-child {
            order: 1;
        }
    }

    .currPage {
        a {
            padding: 12px 0;
            background-color: transparent;
            background-size: 100% 2px;
            background-position: right calc(100% - 10px);

            &:hover {
                background-color: transparent;
            }
        }
    }

    .nav__list {
        flex-direction: column;
        align-items: flex-start;
        gap: 0;
    }
}

@media (max-width: 414px) {
    .footer {
        padding: 2rem 0;
    }

    .copyright {
        padding-top: 1.25rem;
    }
}
